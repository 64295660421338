@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb:255, 255, 255;
    --background-start-rgb: 65,105,225;
    --background-end-rgb: 255, 255, 255;
}

body {
    color: rgb(var(--foreground-rgb));
}

@layer utilities {
    .text-balance {
        text-wrap: balance;
    }
}
